@import '~mdb-ui-kit/css/mdb.min.css';
@import url('https://fonts.googleapis.com/css2?family=Kanit:ital,wght@1,100&family=Lobster&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Reddit+Mono:wght@200..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Reddit+Mono:wght@200..900&family=Teachers:ital,wght@0,400..800;1,400..800&display=swap');
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.card:hover {
  transform: scale(1.05);
  transition: transform 0.3s ease;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.button-pop-out {
  transition: transform 0.2s ease;
}

.button-pop-out:hover {
  transform: scale(1.1);
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

.slideInLeft {  
  animation: 1s ease-out 0s 1 slideInFromLeft;
}

@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

.slideInRight {  
  animation: 1s ease-out 0s 1 slideInFromRight;
}

@keyframes slideInFromBottom {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

.slideInBottom {  
  animation: 1s ease-out 0s 1 slideInFromBottom;
}

.reveal{
  position: relative;
  transform: translateY(150px);
  opacity: 0;
  transition: 1s all ease;
}

.reveal.active{
  transform: translateY(0);
  opacity: 1;
}

.card1 {
  position: relative;
  overflow: hidden;
  height: auto;
  min-height: 400px;
}

/* This fixed horizontal scroll on browser */
body {
  overflow-x: hidden;
}

@keyframes growAndFadeIn {
  0% {
      transform: scale(0.1);
      opacity: 0;
  }
  100% {
      transform: scale(1);
      opacity: 1;
  }
}

.animated-form {
  animation: growAndFadeIn 1s ease-out;
}

.formOutline {
  position: relative;
  margin-bottom: 1.5rem;
}

.formOutline input,
.formOutline textarea {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.formOutline label {
  position: absolute;
  top: -1.25rem;
  left: 10px;
  font-size: 0.75rem;
  color: #555;
  padding: 0 5px;
}

.formOutline input:focus,
.formOutline textarea:focus {
  border-color: #16918b;
  outline: none;
}

.fade-in {
  animation: fadeIn 0.5s ease-in-out forwards; /* Animation duration and easing */
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.custom-navbar-collapse {
  /* background-color: white; Set background color for dropdown links */
  padding: 10px; /* Adjust padding as needed */
}

/* src/components/OurProcessSection.css */
.our-process-container {
  position: relative;
  display: inline-block;
}

.our-process-image {
  display: block;
  width: 100%;
  height: auto;
}

.blur-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: inherit;
  filter: blur(20px); /* Adjust the blur intensity as needed */
 /* Adjust based on the blur intensity to ensure it covers the edges */
}